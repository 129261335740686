import * as React from "react"
import { Link, HeadFC, PageProps } from "gatsby"
import "../styles.scss"

const NotFoundPage: React.FC<PageProps> = () => {
  const today = new Date().getFullYear();

  return (
    <section className="hero is-black is-fullheight">
      <div className="hero-body">
        <div className="container has-text-centered">
          <h1 className="title header-title">
            <span className="is-purple">F</span>
            <span className="is-pink">K</span>
            <span className="is-purple">P</span>
          </h1>
          <p className="subtitle">Oops! Looks like that page doesn't exist.</p>
          <Link to="/"><button className="button home-btn">Home</button></Link>
          <hr className="line"/>
        </div>
      </div>
      <div className="hero-foot">
        <div className="container has-text-centered">
          <p>© Copyright <time dateTime={`${today}-01-01`}>{today}</time></p>
        </div>
      </div>
    </section>
  )
}

export default NotFoundPage

export const Head: HeadFC = () => (
  <>
    <html lang="en" />
    <title>Not Found | Fresh KPop</title>
    <meta name="description" content="A global KPop community with the latest updates" />
  </>
)